import React from "react"
import { graphql } from 'gatsby'

const StaticPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
        <div
          className="static-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
  )
}

export const staticQuery = graphql`
  query($slug: String!) {
  markdownRemark(frontmatter: { slug: { eq: $slug } }) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      slug
      title
    }
  }
}
`;

export default StaticPage
